const purple = '#592ea0';
const white = '#FFF';
const red = '#db2156';
const orange = '#ff8104';
const yellow = '#f5d800';
const green = '#8bdb00';
const lightBlue = '#0EC3E3';
const blue = '#007aff';
const darkBlue = '#222c66';
const grey = '#a6a6a6';
const black = '#000';

const shades = {
  purple: { normal: '#592ea0', dark: '#3e2070', light: '#7a57b3' },
  red: { normal: '#db2156', dark: '#99173c', light: '#e24d77' },
  orange: { normal: '#ff8104', dark: '#b25a02', light: '#ff9a36' },
  yellow: { normal: '#f5d800', dark: '#ab9700', light: '#f7df33' },
  green: { normal: '#8bdb00', dark: '#619900', light: '#a2e233' },
  lightBlue: { normal: '#0EC3E3', dark: '#09889e', light: '#3ecfe8' },
  blue: { normal: '#007aff', dark: '#0055b2', light: '#3394ff' },
  darkBlue: { normal: '#222c66', dark: '#171e47', light: '#4e5684' },
  black: { normal: '#000', light: '#2b2b2b' },
  grey: { normal: '#a6a6a6', light: '#EFEFEF' },
};

module.exports.purple = purple;
module.exports.red = red;
module.exports.white = white;
module.exports.orange = orange;
module.exports.yellow = yellow;
module.exports.green = green;
module.exports.lightBlue = lightBlue;
module.exports.blue = blue;
module.exports.darkBlue = darkBlue;
module.exports.grey = grey;
module.exports.black = black;
module.exports.shades = shades;
